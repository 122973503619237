<template>
  <div class="selectBigBox">
    <div class="selectBigBoxBottom">{{versionNum}}</div>
    <el-card class="box-card">
      <span class="selectBigBoxTit">实验教学测评管理系统</span>
      <div class="card1">
        <router-link class="linkBox linkBox1" tag="div" to="/manageLogin">
          <img src="../../assets/login/selectLoginManage.png" />
        </router-link>
      </div>
      <div class="card2">
        <div class="card2-top">
          <router-link class="linkBox linkBox2" tag="div" to="/scoreLogin">
            <img src="../../assets/login/selectLoginScore.png" />
          </router-link>
        </div>
        <div class="card2-bottom">
          <router-link class="linkBox linkBox4" tag="div" to="/checkInLogin">
            <img src="../../assets/login/selectLoginDraw.png" />
          </router-link>
        </div>
      </div>
      <div class="card3">
        <div class="card1-top">
          <router-link class="linkBox linkBox1" tag="div" to="/examAfterScore">
            <img src="../../assets/login/selectLoginAfterScore.png" />
          </router-link>
        </div>
        <div class="card1-bottom">
          <router-link class="linkBox linkBox4" tag="div" to="/drawLotsLogin">
            <img src="../../assets/login/selectLoginCheckIn.png" />
          </router-link>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getVersion } from "@/api/auth.js";
import { getLoginRoad, removeLoginRoad } from '@/utils/token.js'
export default {
  name: "selectLogin",
  data () {
    return {
      versionNum: 'msetp.school.v3.0.0'
    }
  },
  created () {
    console.log(!!getLoginRoad());
    !!getLoginRoad() && removeLoginRoad()
  },
  methods: {
    getVersionFunc () {
      getVersion().then(res => {
        console.log(res);
        if (res.success) {
          this.versionNum = res.data
        } else {
          this.versionNum = `版本获取失败，code:${res.code}`
        }
      })
    }
  },
  mounted () {
    this.getVersionFunc()
  }
}
</script>


<style scoped>
.selectBigBox {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url(../../assets/login/selectLoginBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectBigBoxBottom {
  position: absolute;
  color: #fff;
  bottom: 30px;
  font-size: 18px;
  left: 0;
  width: 100%;
  text-align: center;
}
.selectBigBoxTit {
  position: absolute;
  width: 100%;
  text-align: center;
  display: block;
  top: -120px;
  left: 0;
  font-size: 60px;
  font-weight: bold;
  line-height: 60px;
  height: 60px;
  letter-spacing: 20px;
  color: #fff;
  z-index: 5;
}
::v-deep .el-card {
  overflow: visible;
}
::v-deep .box-card {
  position: relative;
  width: 1040px;
  height: 476px;
  padding: 0;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 1px #ffffffa8;
}
::v-deep .el-card__body {
  position: relative;
  box-sizing: border-box;
  padding: 32px 30px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .el-card__body > div {
  flex: 1;
  width: 33.333%;
  height: 100%;
}
.card1 {
  padding-right: 15px;
}
.card2 {
  padding: 0 15px;
}
.card3 {
  padding-left: 15px;
}
.linkBox {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s;
}
.linkBox:hover {
  transform: scale(1.1);
}
.linkBox img {
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  /* width: 100%; */
  height: 100%;
}
.linkBox1 img {
  box-shadow: 2px 2px 4px 1px #1a7edc;
}
.linkBox2 img {
  box-shadow: 2px 2px 4px 1px #00cdf0;
}
.linkBox3 img {
  box-shadow: 2px 2px 4px 1px #0ba7f5;
}
.linkBox4 img {
  box-shadow: 2px 2px 4px 1px #08c5a3;
}
.card1-top {
  width: 100%;
  height: 50%;
  padding-bottom: 15px;
  box-sizing: border-box;
}
.card1-bottom {
  padding-top: 15px;
  width: 100%;
  height: 50%;
  box-sizing: border-box;
}
.card2-top {
  width: 100%;
  height: 50%;
  padding-bottom: 15px;
  box-sizing: border-box;
}
.card2-bottom {
  padding-top: 15px;
  width: 100%;
  height: 50%;
  box-sizing: border-box;
}
</style>